import React, { useState, useEffect } from 'react';
import '../components_css/UploadPage.css';
import ProteinModel from './ProteinModel';

const UploadPage = ({ setActivePage }) => {
  const [inputId, setInputId] = useState('');
  const [sequence, setSequence] = useState('');
  const [file, setFile] = useState(null);
  const [pdbFilePath, setPdbFilePath] = useState('/1lyz.pdb');
  const [loading, setLoading] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const readCSV = async () => {
      try {
        const response = await fetch('/default_inputs.csv');
        const text = await response.text();
        const lines = text.split('\n');
        const firstLine = lines[1];
        const [id, seq] = firstLine.split(',');
        setInputId(id.trim());
        setSequence(truncateSequence(seq.trim())); 
      } catch (error) {
        console.error('Error reading the CSV file:', error);
      }
    };

    const timer = setTimeout(() => setLoading(false), 10000); 

    readCSV();
    return () => clearTimeout(timer);
  }, []);

  const truncateSequence = (seq, maxLength = 30) => {
    return seq.length > maxLength ? `${seq.substring(0, maxLength)}...` : seq;
  };

  const handleNextClick = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setActivePage('main');
      setIsTransitioning(false);
    }, 4000);
  };

  const handleBackClick = () => {
    setActivePage('input'); 
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleFileInput = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  return (
    <div className="upload-container">
      {isTransitioning && <div className="transition-overlay">
        <div className="loader"></div>
      </div>}
      <div className="left-panel">
        <div className="sample-info">
          <h2>Sample Information</h2>
          <p><strong>ID:</strong> {inputId}</p>
          <p><strong>Sequence:</strong> {sequence}</p>
        </div>
        <div 
          className="file-drop-area"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleFileDrop}
        >
          <p>Drag and drop your file here</p>
          <p>or</p>
          <input 
            type="file" 
            id="file-input" 
            onChange={handleFileInput} 
            style={{display: 'none'}}
          />
          <label htmlFor="file-input" className="file-input-label">
            Choose File
          </label>
          {file && <p>Selected file: {file.name}</p>}
        </div>
        <div className="button-container">
        <button className="nav-button" onClick={handleBackClick}>
            ← Back
          </button>
          <button className="next-button" onClick={handleNextClick}>
            Next →
          </button>
        </div>
      </div>
      <div className="right-panel">
        {loading && <div className="loader-overlay">
          <div className="loader"></div>
        </div>}
        <ProteinModel pdbFilePath={pdbFilePath} />
      </div>
    </div>
  );
};

export default UploadPage;
