import React, { useState } from 'react';
import InputPage from './components/InputPage';
import UploadPage from './components/UploadPage';
import MainPage from './components/MainPage';
import LoadingPage from './components/LoadingPage';
import ESummaryPage from './components/ESummaryPage';
import TreePage from './components/TreePage';
import './App.css';

function App() {
  const [activePage, setActivePage] = useState('input');
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);

  const enableButtons = () => {
    setButtonsEnabled(true);
  };
  const renderPage = () => {
    switch (activePage) {
      case 'input':
        return <InputPage setActivePage={setActivePage} />;
      case 'upload':
        return <UploadPage setActivePage={setActivePage} />;
   
      case 'esummary':
        return <ESummaryPage setActivePage={setActivePage} />;


      case 'tree':
        return <TreePage setActivePage={setActivePage} enableMainPageButtons={enableButtons} />;


      
      default:
        return <InputPage setActivePage={setActivePage} />;


      case 'loading':
        return <LoadingPage setActivePage={setActivePage} setLoadingCompleted={setLoadingCompleted} />;
      case 'main':
        return <MainPage setActivePage={setActivePage} loadingCompleted={loadingCompleted} buttonsEnabled={buttonsEnabled} />;
        
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {renderPage()}
      </header>
    </div>
  );
}

export default App;
