import React, { useEffect, useRef } from 'react';
import * as $3Dmol from '3dmol';

const ProteinModel = ({ pdbFilePath }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchPDBFile = async (filePath) => {
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error('Failed to load PDB file');
        }
        const pdbData = await response.text();
        return pdbData;
      } catch (error) {
        console.error('Error loading PDB file:', error);
        return null;
      }
    };

    if (containerRef.current && pdbFilePath) {
      fetchPDBFile(pdbFilePath).then((pdbData) => {
        if (pdbData) {
          const viewer = $3Dmol.createViewer(containerRef.current, {
            defaultcolors: $3Dmol.rasmolElementColors,
            backgroundColor: 'rgba(0,0,0,0)', // Ensure transparency
          });

          viewer.addModel(pdbData, "pdb");
          viewer.setStyle({}, { cartoon: { color: 'spectrum' } });
          viewer.zoomTo();
          viewer.render();

          // Initialize the model smaller by zooming out
          viewer.zoom(0.5, 1000); // Adjust this value to make the model smaller
        } else {
          containerRef.current.innerHTML = 'Failed to load the PDB structure';
        }
      });
    }
  }, [pdbFilePath]);

  return (
    <div 
      ref={containerRef} 
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0)' // Explicitly set transparent background
      }}
    ></div>
  );
};

export default ProteinModel;
