import React, { useState, useEffect } from 'react';
import '../components_css/LoadingPage.css';

function LoadingPage({ setActivePage, setLoadingCompleted }) {
  // Initialize state with random numbers for each type of correlated factor
  const [progress, setProgress] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(new Array(5).fill(false));
  const [highCorrelated] = useState(Math.floor(Math.random() * (4 - 1 + 1)) + 1);  // 1 to 4
  const [mediumCorrelated] = useState(Math.floor(Math.random() * (7 - 3 + 1)) + 3); // 3 to 7
  const [lowCorrelated] = useState(Math.floor(Math.random() * (20 - 10 + 1)) + 10); // 10 to 20

  const tasks = [
    'Database Search',
    'Running Protein Language Models',
    'Running Structural Simulations',
    'Running Molecular Dynamics Simulations',
    'Building Combined Model'
  ];

  useEffect(() => {
    let totalTime = 0;
    const randomTimes = tasks.map(() => Math.floor(Math.random() * 2000)); // Generate random times between 0 and 5 seconds for each task

    tasks.forEach((task, index) => {
      totalTime += randomTimes[index];
      setTimeout(() => {
        setCompletedTasks(current => {
          const updated = [...current];
          updated[index] = true; // Set the task at this index as completed
          return updated;
        });
        if (index === tasks.length - 1) {
          // Ensure the progress bar reaches 100% only when the last task is fully processed
          setProgress(100);
        } else {
          setProgress((prevProgress) => prevProgress + 100 / tasks.length);
        }
      }, totalTime);
    });
  }, [tasks]); // Dependencies are only the tasks array, which doesn't change

  const allTasksCompleted = completedTasks.every(Boolean); // Check if all tasks are true

  const handleNextClick = () => {
    setLoadingCompleted(true);
    setActivePage('main');
  };

  return (
    <div className="gradient-background">
    <div className="loading-container">
      <div className="loading-tasks">
        {tasks.map((task, index) => (
          <p key={index} className={completedTasks[index] ? 'active-task' : ''}>
            {task}
          </p>
        ))}
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      {allTasksCompleted && ( // Render the summary only when all tasks are completed
        <div className="summary-container">
          <h2>Experimental Summary</h2>
          <p>{highCorrelated} Highly Correlated Factors</p>
          <p>{mediumCorrelated} Medium Correlated Factors</p>
          <p>{lowCorrelated} Low Correlated Factors</p>
          <button onClick={handleNextClick}>Next</button>
        </div>
      )}
    </div>
    </div>
  );
}

export default LoadingPage;
