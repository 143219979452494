import React, { useState, useEffect } from 'react';
import '../components_css/InputPage.css'; // Import the CSS file

const InputPage = ({ setActivePage }) => {
  const [sequence, setSequence] = useState('');
  const [inputId, setInputId] = useState('');

  useEffect(() => {
    const readCSV = async () => {
      try {
        const response = await fetch('/default_inputs.csv'); // This points to the file in the public directory
        const text = await response.text();
        const lines = text.split('\n');
        const firstLine = lines[1]; // Assuming the first line is the header
        const [id, seq] = firstLine.split(',');
        setInputId(id.trim());
        setSequence(seq.trim());
      } catch (error) {
        console.error('Error reading the CSV file:', error);
      }
    };

    readCSV();
  }, []);

  const handleGoClick = () => {
    console.log('Sequence:', sequence);
    console.log('Input ID:', inputId);
    setActivePage('upload');
  };

  return (
    <div className="container">
      <div className="header">
        <img src="AA Logo Centred.png" alt="AminoAnalytica" className="header-image" />
      </div>
      <div className="input-group">
        <label htmlFor="sequence" className="label">Input Sequence</label>
        <input
          id="sequence"
          type="text"
          value={sequence}
          onChange={(e) => setSequence(e.target.value)}
          className="input"
          placeholder="Enter sequence here"
        />
      </div>
      <div className="input-group">
        <label htmlFor="inputId" className="label">Input ID</label>
        <input
          id="inputId"
          type="text"
          value={inputId}
          onChange={(e) => setInputId(e.target.value)}
          className="input"
          placeholder="Enter ID here"
        />
      </div>
      <button onClick={handleGoClick} className="button">
        <span className="button-text">Optimize</span>
        <span className="button-icon">➔</span>
      </button>
    </div>
  );
};

export default InputPage;
