import React, { useState, useEffect } from 'react';
import ProteinModel from './ProteinModel';
import '../components_css/MainPage.css';
import Modal from './Modal';

function MainPage({ setActivePage, loadingCompleted, buttonsEnabled }) {
  const [proteinId, setProteinId] = useState('');
  const [proteinSeq, setProteinSeq] = useState('');
  const [pdbFilePath, setPdbFilePath] = useState('test_e4f6b_unrelaxed_rank_001_alphafold2_multimer_v3_model_2_seed_000.pdb');
  const [generationStats, setGenerationStats] = useState('Start Proxy Search to generate statistics.');
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);
  useEffect(() => {
    const readCSV = async () => {
      try {
        const response = await fetch('/default_inputs.csv');
        const text = await response.text();
        const lines = text.split('\n');
        const firstLine = lines[1];
        const [id, seq] = firstLine.split(',');
        setProteinId(id.trim());
        setProteinSeq(truncateSequence(seq.trim()));
      } catch (error) {
        console.error('Error reading the CSV file:', error);
      }
    };

    readCSV();
  }, []);

  const truncateSequence = (seq, maxLength = 30) => {
    return seq.length > maxLength ? `${seq.substring(0, maxLength)}...` : seq;
  };

  useEffect(() => {
    if (buttonsEnabled) {
      setGenerationStats(
        <div className="biotech-summary">
          <div className="summary-header">
            
            <p className="stat">🔬 <strong>Number of Proteins:</strong> 14</p>
          </div>
  
      
  
          <div className="stats-section">
  <h4>🏆 Best in Generation</h4>
  <div className="stats-grid">
    <div className="stat-group">
      <p className="stat">🔥 <strong>Thermostability:</strong> &lt;67°C</p>
      <p className="stat">⚖️ <strong>pH Stability:</strong> 4-10</p>
      <p className="stat">💧 <strong>Solvent Stability:</strong> 72%</p>
    </div>
    <div className="stat-group">
      <p className="stat">📈 <strong>Expression Level:</strong> High</p>
      <p className="stat">💧 <strong>Solubility:</strong> 93%</p>
    </div>
    <div className="stat-group">
      <p className="stat">🔬 <strong>Evolvability:</strong> Very High</p>
      <p className="stat">🔄 <strong>Fold Likelihood:</strong> 92%</p>
    </div>
    <div className="stat-group">
      <p className="stat">🎯 <strong>Target Label:</strong> 21.4</p>
      <p className="stat">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[Catalytic Activity U/mg]</p>
    </div>
  </div>
</div>
<div className="stats-section">
  <h4>📊 Average Statistics</h4>
  <div className="stats-grid">
    <div className="stat-group">
      <p className="stat">🌡️ <strong>Thermostability:</strong> &lt;59°C</p>
      <p className="stat">⚖️ <strong>pH Stability:</strong> 5-9</p>
      <p className="stat">💧 <strong>Solvent Stability:</strong> 62%</p>
    </div>
    <div className="stat-group">
      <p className="stat">📈 <strong>Expression Level:</strong> Medium</p>
      <p className="stat">💧 <strong>Solubility:</strong> 85%</p>
    </div>
    <div className="stat-group">
      <p className="stat">🔬 <strong>Evolvability:</strong> High</p>
      <p className="stat">🔄 <strong>Fold Likelihood:</strong> 87%</p>
    </div>
    <div className="stat-group">
      <p className="stat">🎯 <strong>Target Label:</strong> 18.8</p>
      <p className="stat">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[Catalytic Activity U/mg]</p>
    </div>
  </div>
</div>

        </div>
      );
    }
  }, [buttonsEnabled]);

  const handleProxySearchClick = () => {
    setActivePage('loading');
  };

  const handleAccelerateClick = () => {
    setActivePage('tree');  // Assuming 'tree' leads to 'accelerate'
  };

  // In MainPage component
const handleViewSummaryClick = () => {
  setActivePage('esummary'); // This will set the active page to 'esummary'
};


  const handleUploadClick = () => {
    document.getElementById('fileUpload').click(); // Trigger the hidden file input
  };
  
  const handleFileChange = (event) => {
    console.log(event.target.files); // Log the files, this can be replaced with any action
    alert('Files ready for upload!'); // Just a placeholder interaction
  };
  
  const handleDownloadClick = () => {
  const link = document.createElement('a');
  link.href = 'protease_88_mutated.fasta'; // Ensure the path is correct, it might need to be '/sequence_data.fasta' or the full path depending on setup
  link.download = 'protease_88_mutated.fasta';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};




  return (
    <div className="main-container">
      <div className="top-left">
    <div className="protein-info">
      <h3>Protein Information</h3>
      <p><strong>ID:</strong> {proteinId}</p>
      <p><strong>Sequence:</strong> {proteinSeq.slice(0, 50)}...</p>
    </div>
    <div className="site-info">
      <h3>Site Details</h3>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Positions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Active Sites</td>
            <td>188, 221, 297</td>
          </tr>
          <tr>
            <td>Binding Sites</td>
            <td>392, 395, 396, 438, 441, 442</td>
          </tr>
        </tbody>
      </table>
    </div>
</div>

      <div className="bottom-left">
        <button onClick={handleProxySearchClick}>Proxy Search</button>
        <button onClick={handleAccelerateClick} disabled={!loadingCompleted}>Accelerate</button>
        <button disabled={!buttonsEnabled} onClick={toggleModal}>View Tree</button>
        <Modal isOpen={isModalOpen} onClose={toggleModal}>
        <img src="View Tree Screenshot.png" alt="Logo" />
        </Modal>
        <button onClick={handleDownloadClick} disabled={!buttonsEnabled}>Download Sequences</button>
        <button onClick={handleUploadClick} disabled={!buttonsEnabled}>Upload Data</button>
        
        
        <button onClick={handleViewSummaryClick} disabled={!buttonsEnabled}>View Summary</button>

      </div>
      <div className="top-right">
      <div className="wildtype-stats">
  <h3>🧬 WildType Statistics</h3>
  
  <div className="stats-grid">
  
  <div className="stat-group summary">
    <p className="stat">🌡️ <strong>Thermostability:</strong> &lt;45°C</p>
    <p className="stat">⚖️ <strong>pH Stability:</strong> 6-10</p>
    <p className="stat">💧 <strong>Solvent Stability:</strong> 55%</p>
  </div>

  <div className="stat-group summary">
    <p className="stat">📈 <strong>Expression Level:</strong> High</p>
    <p className="stat">💧 <strong>Solubility:</strong> 55%</p>
  </div>

  <div className="stat-group summary">
    <p className="stat">🔬 <strong>Evolvability:</strong> High</p>
    <p className="stat">🔄 <strong>Fold Likelihood:</strong> 85%</p>
  </div>

  <div className="stat-group summary">
    <p className="stat">🎯 <strong>Target Label:</strong> 16.2</p>
    <p className="stat">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[Catalytic Activity U/mg]</p>
  </div>

</div>


</div>
</div>

      <div className="bottom-right">
        <h3>Generation Statistics</h3>
        <p>{generationStats}</p> 
        
        </div>
      <input type="file" id="fileUpload" style={{ display: 'none' }} onChange={handleFileChange} multiple />
    </div>
  );
}

export default MainPage;
