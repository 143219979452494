import React, { useState, useEffect } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [children]);

  // Inject CSS to hide scrollbar for Webkit browsers
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = `.imageContainer::-webkit-scrollbar { display: none; }`;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  if (!isOpen) return null;

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    transition: 'opacity 0.3s ease-in-out',
    opacity: imageLoaded ? 1 : 0,
    objectFit:'contain',
    
  };

  const closeButtonStyle = {
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 10,
    border: 'none',
    background: 'none',
    fontSize: '1.5rem',
    color: 'white',
    zIndex: 2
  };

  const modalContentStyle = {
    // position: 'relative',
    background: 'black',
    // borderRadius: 5,
    // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    width: '80vw',
    height: '80vh',
    // maxWidth: '1000px',
    // maxHeight: '1000px',
    
    // overflow: 'auto' // Ensure overflow handling
    
  };

  const imageContainerStyle = {
    width: '100%',
    height: '100%',
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    scrollbarWidth: 'none',  // For Firefox
    msOverflowStyle: 'none',  // For IE/Edge
    
   
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      
      
    }}>
      <div style={modalContentStyle}>
        <div className="imageContainer" style={imageContainerStyle}>
          {typeof children === 'string' ? (
            <img
              src={children}
              alt=""
              style={imageStyle}
              onLoad={() => setImageLoaded(true)}
            />
          ) : children}
        </div>
        <button style={closeButtonStyle} onClick={onClose}>&times;</button>
      </div>
    </div>
  );
};

export default Modal;
