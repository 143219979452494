import React, { useState, useEffect } from 'react';
import '../components_css/UploadPage.css'; 
import ProteinModel from './ProteinModel';

function ESummaryPage({ setActivePage }) {
  const [inputId, setInputId] = useState('optimised32');
  const [sequence, setSequence] = useState('');
  const [pdbFilePath, setPdbFilePath] = useState('/1lyz.pdb');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const readCSV = async () => {
      try {
        const response = await fetch('/default_inputs.csv');
        const text = await response.text();
        const lines = text.split('\n');
        const firstLine = lines[1];
        const [, seq] = firstLine.split(',');
        setSequence(truncateSequence(seq.trim()));
      } catch (error) {
        console.error('Error reading the CSV file:', error);
      }
    };

    const timer = setTimeout(() => setLoading(false), 10000); // Placeholder for simulated loading

    readCSV();
    return () => clearTimeout(timer);
  }, []);

  const truncateSequence = (seq, maxLength = 30) => {
    return seq.length > maxLength ? `${seq.substring(0, maxLength)}...` : seq;
  };

  const handleBackClick = () => {
    setActivePage('main'); 
  };

  return (
    <div className="upload-container">
      <div className="left-panel">
        <div className="sample-info">
          <h2>🏆 Best in Generation</h2>
          <p><strong>ID:</strong> PROTEASE_88.5.40</p>
          <p><strong>Sequence:</strong> {sequence}</p>
        </div>
        <div className="stats-section">
        <div className="stats-grid">
        <div className="stat-group">
      <p className="stat">🔥 <strong>Thermostability:</strong> &lt;67°C</p>
      <p className="stat">⚖️ <strong>pH Stability:</strong> 4-10</p>
      <p className="stat">💧 <strong>Solvent Stability:</strong> 72%</p>
    </div>
    <div className="stat-group">
      <p className="stat">📈 <strong>Expression Level:</strong> High</p>
      <p className="stat">💧 <strong>Solubility:</strong> 93%</p>
    </div>
    <div className="stat-group">
      <p className="stat">🔬 <strong>Evolvability:</strong> Very High</p>
      <p className="stat">🔄 <strong>Fold Likelihood:</strong> 92%</p>
    </div>
    <div className="stat-group">
      <p className="stat">🎯 <strong>Target Label:</strong> 21.4</p>
      <p className="stat">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[Catalytic Activity U/mg]</p>
    </div>
            </div>
          </div>
          <div className="stats-section">
            <h4>📊 Average Statistics</h4>
            <div className="stats-grid">
            <div className="stat-group">
      <p className="stat">🌡️ <strong>Thermostability:</strong> &lt;59°C</p>
      <p className="stat">⚖️ <strong>pH Stability:</strong> 5-9</p>
      <p className="stat">💧 <strong>Solvent Stability:</strong> 62%</p>
    </div>
    <div className="stat-group">
      <p className="stat">📈 <strong>Expression Level:</strong> Medium</p>
      <p className="stat">💧 <strong>Solubility:</strong> 85%</p>
    </div>
    <div className="stat-group">
      <p className="stat">🔬 <strong>Evolvability:</strong> High</p>
      <p className="stat">🔄 <strong>Fold Likelihood:</strong> 87%</p>
    </div>
    <div className="stat-group">
      <p className="stat">🎯 <strong>Target Label:</strong> 18.8</p>
      <p className="stat">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[Catalytic Activity U/mg]</p>
    </div>
  </div>
          </div>
          
        <button className="nav-button" onClick={handleBackClick}>
          ← Back
        </button>
      </div>
      <div className="right-panel">
        {loading && <div className="loader-overlay">
          <div className="loader"></div>
        </div>}
        <ProteinModel pdbFilePath={pdbFilePath} />
      </div>
    </div>
  );
}

export default ESummaryPage;
