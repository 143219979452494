import React, { useState } from 'react';
import '../components_css/TreePage.css';

function TreePage({ setActivePage, enableMainPageButtons }) {
  const [buttonLabel, setButtonLabel] = useState('Next');
  const videoRef = React.useRef(null);

  const handleButtonClick = () => {
    enableMainPageButtons(); // Enable the buttons on MainPage before navigating
    setActivePage('main');
  };

  return (
    <div className="tree-page-container">
      <h1 className="header"></h1>
      <div className="video-viewer">
        <video
          ref={videoRef}
          autoPlay
          muted
          disablePictureInPicture
          controlsList="nodownload nofullscreen noremoteplayback"
        >
          <source src="/Amino Demo - Tree Generation.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <button className="control-button" onClick={handleButtonClick}>
        {buttonLabel}
      </button>
    </div>
  );
}

export default TreePage;
